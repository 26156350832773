export const errors = {
  TAG_EXISTS: 'This tag already exists',
  NO_ONGOING_ORDER: 'There is no ongoing order right now. Please initiate a new order via Whatsapp or Website.',
  INVALID_LINK: 'This link is no longer available.\nPlease initiate a new order via Whatsapp or Website.',
  ADDRESS_NOT_FOUND: 'We could not find your address. Please add manually.',
  ADDRESS_OUT_OF_RANGE: (area) => `Address out of range. Please select address in ${area}.`,
  AREA_OUT_OF_RANGE: "We don't offer services in this area.",
  ACCESS_REQUIRED: `To sort by Nearest Shops, please allow location access in your browser settings.`,
  CAPTCHA_FAILED: 'Captcha Failed.',
};

export const errorsCodes = {
  UNAUTHORIZED_CODE: 401,
  FORBIDDEN_CODE: 403,
  SERVER_ERROR: 500,
  NOT_FOUND_CODE: 404,
  NETWORK_ERROR: "ERR_NETWORK",
};

import React, { useState } from "react";
import { AddressModal } from "modals";
import { pages } from "common/constants";
import { BsPencil } from "react-icons/bs";
import { local } from "common";

const ChangeLocationButton = ({ area }) => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [newAddress, setNewAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState({id: null, title: ""});
  const userAddress = local.getSelectedLocation();

  return (
    <>
      <a className="userAddr" onClick={() => setShowAddressModal(true)}>
        <span>
          {userAddress ? `Address: ${userAddress.title}` : 'Current Location'}
          <BsPencil className="edit"/>
        </span>
      </a>
      {showAddressModal && (
        <AddressModal
          shopArea={area}
          show={showAddressModal}
          setShow={setShowAddressModal}
          setNewAddress={setNewAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          isUpdate={false}
          page={pages.VENDORS}
        />
      )}
    </>
  );
};

export default ChangeLocationButton;

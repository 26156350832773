import React from "react";
import { Placeholder } from "react-bootstrap";

const Loading = ({ containerClass, outerClass, innerClass, count }) => {
  return (
    <div className={containerClass}>
      {[...Array(count)].map((_, index) => (
        <Placeholder as="div" animation="glow" className={outerClass}>
          <Placeholder xs={12} className={innerClass} />
        </Placeholder>
      ))}
    </div>
  );
};

export default Loading;

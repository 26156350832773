import React, { useEffect, useState } from "react";
import { AddAddress, EditAddress, ChooseAddress } from "modals";
import { Modal } from "react-bootstrap";
import { globals, local } from "common";

function AddressModal({
  centroid,
  shopArea,
  show,
  setShow,
  setCustomerAddresses,
  setNewAddress,
  selectedAddress,
  setSelectedAddress,
  isUpdate,
  setIsUpdate,
  page,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [addManual, setAddManual] = useState(false);
  const [showChooseAddressModal, setShowChooseAddressModal] = useState(false);
  const [pin, setPin] = useState(null);
  const [address, setAddress] = useState(globals.DEFAULT_ADDRESS);

  const closeModal = () => {
    setShow(false);
    isUpdate && setIsUpdate(false);
    setShowEditModal(false);
    setSelectedAddress({ id: null, title: "" });
  };

  useEffect(() => {
    if (isUpdate) {
      setShow(true);
    }
  }, [isUpdate]);

  useEffect(()=>{
    if(addManual) {
      setShowEditModal(true);
    }
  }, [addManual])

  useEffect(() => {
    if(local.getSelectedLocation()) {
      setPin(local.getSelectedLocation()?.coordinates)
    } else {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setPin({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          setPin(centroid ? centroid : globals.DEFAULT_PIN);
        }
      );
    }
  }, []);

  return (
    <div>
      <Modal
        className="dAddressModal"
        show={show}
        fullscreen="md-down"
        centered
        onHide={closeModal}
      >
       {(!showEditModal && !showChooseAddressModal) && 
        <AddAddress
          shopArea={shopArea} address={address} setAddress={setAddress} setCustomerAddresses={setCustomerAddresses}
          addManual={addManual} setAddManual={setAddManual} setShowEditModal={setShowEditModal} show={show}
          setShow={setShow} setShowChooseAddressModal={setShowChooseAddressModal}
          pin={pin} setPin={setPin} setNewAddress={setNewAddress} selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress} isUpdate={isUpdate} setIsUpdate={setIsUpdate} page={page}
        />
      }

       {(showEditModal && !showChooseAddressModal) && 
        <EditAddress
          shopArea={shopArea} address={address} setAddress={setAddress} setShow={setShow} setShowEditModal={setShowEditModal}
          selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} isUpdate={isUpdate} addManual={addManual}/>
        }

        {showChooseAddressModal && 
          <ChooseAddress shopArea={shopArea} setShowChooseAddressModal={setShowChooseAddressModal}/>
        }
        
      </Modal>
    </div>
  );
}

export default AddressModal;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { BsPencilSquare, BsPlusCircle } from "react-icons/bs";
import { LoaderBtn, Loading, Map } from "components";
import { labels, local, utils } from "common";
import { clauses, messages, pages } from "common/constants";
import { generalExtraActions } from "reduxStore/actions";
import { api } from "api";
import { toast } from "react-toastify";
import "modals/addressModals/_addressModals.scss";

function AddAddress({
  address,
  setAddress,
  shopArea,
  setShow,
  setCustomerAddresses,
  setShowEditModal,
  addManual,
  setAddManual,
  setShowChooseAddressModal,
  pin,
  setPin,
  setNewAddress,
  selectedAddress,
  setSelectedAddress,
  isUpdate,
  setIsUpdate,
  page,
}) {
  const { token, id: areaIdFromParams } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const areaIdFromSearch = searchParams.get("area");
  const isVendorsPage = page === pages.VENDORS;
  const areaId = areaIdFromSearch || areaIdFromParams;
  const areaName = shopArea?.area;
  const preSelectedAddress = local.getSelectedLocation();
  const isChosenAddress = selectedAddress?.title && !selectedAddress.id;

  async function handleAddAddress() {
    if (!token) return;
    setLoading(true);
    try {
      const addressString = utils.getAddressString(address);
      let ad = { address: addressString, area: shopArea?.id };
      const coordinates = { longitude: pin?.longitude, latitude: pin?.latitude }
      if (address?.plot && !addManual) {
        ad.coordinates = coordinates;
      }
      const res = await api.createCustomerAddresses(token, ad);
      const addresses = await api.getCustomerAddresses({ area: areaId });
      if (!addManual) {
        local.storeSelectedLocation({
          title: addressString,
          coordinates: coordinates,
          isBrowserDetected: false,
          addedToDB: true,
          area: areaId,
        });
      }
      setNewAddress(res?.id);
      setCustomerAddresses(addresses);
      toast.success(messages.ADDRESS_SUCCESS);
      setShow(false);
    } catch (error) {
      utils.showErrorToast(error);
    }
    setLoading(false);
  }

  async function handleUpdateAddress() {
    setLoading(true);
    await api.updateCustomerAddress(selectedAddress.id, {
      address: selectedAddress.title,
      input_coordinates: pin,
    });
    const addresses = await api.getCustomerAddresses({ area: areaId });
    setCustomerAddresses(addresses);
    toast.success(messages.ADDRESS_UPDATE_SUCCESS);
    setLoading(false);
    setShow(false);
    setIsUpdate(false);
  }

  const handleChangeLocation = () => {
    const coordinates = { longitude: pin.longitude, latitude: pin.latitude };
    local.storeSelectedLocation({
      title: utils.getAddressString(address),
      area: parseInt(areaId),
      coordinates: coordinates,
      isBrowserDetected: false,
      addedToDB: false,
    });
    dispatch(
      generalExtraActions.getShopsFromStart({ areaId, params: coordinates })
    );
    setShow(false);
  };

  const handleAddManual = () => {
    setShowEditModal(true);
    setAddManual(true);
  };

  const getButtonConfig = () => {
    if (isVendorsPage) {
      return { onClick: handleChangeLocation, title: labels.SUBMIT };
    }
    if (isUpdate) {
      const onClick = isChosenAddress ? handleAddAddress : handleUpdateAddress;
      return { onClick, title: labels.UPDATE_ADDRESS };
    }
    return { onClick: handleAddAddress, title: labels.ADD_ADDRESS };
  };

  const { onClick, title } = getButtonConfig();

  const renderEditButton = () => (
    <a className="btnEdit" onClick={() => setShowEditModal(true)}>
      <BsPencilSquare />
      Edit
    </a>
  );

  const renderAddAddressButton = () =>
    addresses.length > 0 && isVendorsPage ? (
      <a
        className="addAddress"
        onClick={() => {
          setShowChooseAddressModal(true);
        }}
      >
        <BsPlusCircle className="icon" style={{ marginRight: "8px" }} />
        <span>{clauses.CHOOSE_ADDRESS}</span>
      </a>
    ) : (
      !isVendorsPage && (
        <a className="addAddress" onClick={handleAddManual}>
          <BsPlusCircle className="icon" style={{ marginRight: "8px" }} />
          <span>{clauses.ADD_ADDRESS_MANUALLY}</span>
        </a>
      )
    );

  useEffect(() => {
    const fetchAddresses = async () => {
      if (local.isUserLoggedIn()) {
        const addresses = await api.getCustomerAddresses({
          area: areaId,
          coordinates: true,
        });
        setAddresses(addresses);
      }
    };
    fetchAddresses();
  }, []);

  useEffect(() => {
    if (isUpdate && address?.plot) {
      setSelectedAddress({
        id: selectedAddress.id,
        title: utils.getAddressString(address),
      });
    }
  }, [address]);

  return (
    <div>
      <div>
        <Modal.Header closeButton>
          <Modal.Title as="h1">Pinpoint Your Delivery Address</Modal.Title>
          <p>
            Select your precise location in <strong>"{areaName}"</strong> for
            accurate delivery.
          </p>
          {showAlert && (
            <span className="error">
              <p>{errorMsg}</p>
            </span>
          )}
        </Modal.Header>
        <Modal.Body
          className={`modal-body ${
            !(address?.plot || local.isUserLoggedIn()) ? "no-margin" : ""
          }`}
        >
          <div className="mapWrap">
            {pin?.longitude && pin?.latitude ? (
              <Map
                shopArea={shopArea}
                pin={{ longitude: pin.longitude, latitude: pin.latitude }}
                setPin={setPin}
                draggable={true}
                setAddress={setAddress}
                setShowAlert={setShowAlert}
                setErrorMsg={setErrorMsg}
                isModal={true}
                setAddManual={setAddManual}
              />
            ) : (
              <Loading outerClass={"placeholder"} count={1} />
            )}
          </div>
          {preSelectedAddress && isVendorsPage && (
            <div className="currentAddr">
              <span className="label">Current Address:</span>
              <span className="title">{preSelectedAddress.title}</span>
            </div>
          )}
          <div
            className={`PreviewWrap ${
              isVendorsPage
                ? (local.isUserLoggedIn() && addresses.length > 0) ||
                  address?.plot
                  ? "bgColor"
                  : "guest"
                : "bgColor"
            }`}
          >
            {(address?.plot || isUpdate) && <h4>Preview Address:</h4>}
            <div className="pBody">
              {address?.plot || isUpdate ? (
                <>
                  <div className="addrRow">
                    {isUpdate ? (
                      <span>
                        {selectedAddress?.title ||
                          local.getSelectedLocation()?.title}
                      </span>
                    ) : (
                      <span>
                        {utils.getAddressString(address)}, {areaName}
                      </span>
                    )}
                    {renderEditButton()}
                  </div>
                  {local.isUserLoggedIn() && renderAddAddressButton()}
                </>
              ) : (
                local.isUserLoggedIn() && renderAddAddressButton()
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <LoaderBtn
            onClick={onClick}
            loading={loading}
            title={title}
            disabled={!address.plot || loading || showAlert}
          />
        </Modal.Footer>
      </div>
    </div>
  );
}

export default AddAddress;

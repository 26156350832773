export const tokenKeys = {
  ACCESS: 'accessToken',
  REFRESH: 'refreshToken',
  LAST_LOGIN: 'lastLoginTime'
};

export const local = {
  storeTokens: (tokens) => {
    localStorage.setItem(tokenKeys.ACCESS, tokens.access_token);
    localStorage.setItem(tokenKeys.REFRESH, tokens.refresh_token);
    const currentTime = new Date().getTime();
    localStorage.setItem(tokenKeys.LAST_LOGIN, currentTime.toString())
  },
  storeAccessToken: (access_token) => {
    localStorage.setItem(tokenKeys.ACCESS, access_token);
  },
  getAccessToken: () => {
    return localStorage.getItem(tokenKeys.ACCESS);
  },
  getRefreshToken: () => {
    return localStorage.getItem(tokenKeys.REFRESH);
  },
  clearLocalStorge: () => {
    localStorage.clear();
  },
  storeUser: (user) => {
    const existingUser = JSON.parse(localStorage.getItem('user')) || {};
    const updatedUser = { ...existingUser, ...user };
    localStorage.setItem('user', JSON.stringify(updatedUser));
  },
  getUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },
  isUserLoggedIn: () => {
    return !!local.getAccessToken() && !!local.getUser();
  },
  isLocationAllowed: () => {
    return localStorage.getItem('locationAllowed');
  },
  setIsLocationAllowed: (value) => {
    return localStorage.setItem('locationAllowed', value);
  },
  removeIsLocationAllowed: () => {
    return localStorage.removeItem('locationAllowed');
  },
  getSelectedLocation: () => {
    return JSON.parse(localStorage.getItem('selectedLocation'));
  },
  storeSelectedLocation: (updates) => {
    const existingData = local.getSelectedLocation();
    const updatedData = { ...existingData, ...updates };
    localStorage.setItem('selectedLocation', JSON.stringify(updatedData));
  },
  removeSelectedLocation: () => {
    return localStorage.removeItem('selectedLocation');
  },
};

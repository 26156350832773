import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { authExtraActions } from 'reduxStore/actions';
import { local } from 'common';

const INITIAL_STATE = {
  loading: false,
  isUserLoggedIn: local.isUserLoggedIn(),
  isOTPSent: false,
  inside24Limit: false,
  tokens: {
    access: '',
    refresh: '',
  },
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetAuthSlice: () => INITIAL_STATE,
    logout: (state, ) => {
      state.isUserLoggedIn = false;
      state.isOTPSent = false;
      state.inside24Limit = false;
      local.clearLocalStorge();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authExtraActions.login.fulfilled, (state, {payload}) => {
      const {access, refresh, user} = payload;
      state.loading = false;
      state.tokens = {access, refresh};
      local.storeUser(user);
    });
    builder.addCase(authExtraActions.sendOtp.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.isOTPSent = true;
      state.inside24Limit = payload.inside_24hour_message_limit;
    });
    builder.addCase(authExtraActions.checkOtp.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.inside24Limit = payload.status === 200;
    });
    builder.addCase(authExtraActions.verifyOtp.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.isUserLoggedIn = true;
      local.storeTokens(payload);
      local.storeUser({ profile: payload.profile });
    });
    builder.addCase(authExtraActions.sendOtp.pending, (state, {payload}) => {
      state.loading = true;
      state.isOTPSent = false;
      state.error = null;
    });
    builder.addCase(authExtraActions.sendOtp.rejected, (state, {payload}) => {
      state.loading = false;
      state.isOTPSent = false;
      state.inside24Limit = false;
      state.error = payload;
    });
    builder.addMatcher(
      isAnyOf(
        authExtraActions.login.pending,
        authExtraActions.verifyOtp.pending,
      ),
      (state, {payload}) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        authExtraActions.login.rejected,
        authExtraActions.verifyOtp.rejected,
      ),
      (state, {payload}) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const {actions: authActions, reducer} = authSlice;
export default reducer;

import React, { useEffect, useState } from 'react';
import { IconClock, IconVector } from "../../assets/icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Time } from "../index";
import { weekdays } from "../../common/constants";
import { utils } from "../../common";

function Schedule({shop}) {
    const currentDay = (new Date()).getDay();
    const schedules = shop?.schedules;
    const closedDates = shop?.closed_dates;
    const currentDaySchedule = schedules?.find(schedule => schedule.day === currentDay);
    const isOpen247 = utils.isOpen247(schedules);
    const scheduleExists = schedules?.length > 0;
    const [currentTimeSlot, setCurrentTimeSlot] = useState({opening_time: null, closing_time: null});

    const renderScheduleItem = (schedule, index) => {
        const isOpen = schedule.time_slots?.length > 0 && !utils.closedDays(closedDates).includes(schedule.day);
        return (
        <Time key={index} isCurrent={currentDay === schedule.day} day={weekdays[schedule.day]}
            time_slots={schedule?.time_slots} closed={!isOpen}
        />
        );
    };
 
  const popoverSchedule = (
    <Popover className='schedule' style={{display:'flex', flexDirection:'column'}}>
      {shop?.schedules?.map(renderScheduleItem)}
    </Popover>
  );

  useEffect(()=>{
    let date = new Date();
    const timestamp = date.getTime();
    currentDaySchedule?.time_slots?.forEach((time_slot) => {
      if(time_slot?.opening_time && time_slot?.closing_time) {
        const [openHours, openMinutes, openSeconds] = time_slot?.opening_time?.split(':');
        const [closeHours, closeMinutes, closeSeconds] = time_slot?.closing_time?.split(':');
        
        const openingTime = utils.getTimeStamp(date, openHours, openMinutes, openSeconds);
        if(parseInt(closeHours) < parseInt(openHours)) {
          date.setDate(date.getDate() + 1);
        }
        const closingTime = utils.getTimeStamp(date, closeHours, closeMinutes, closeSeconds);
    
        if (timestamp >= openingTime && timestamp <= closingTime) {
          setCurrentTimeSlot({
            opening_time: time_slot?.opening_time,
            closing_time: time_slot?.closing_time,
          });
        } 
      }
    });
  },[currentDaySchedule])
  
  return (
    <>
        <span><img src={IconClock} alt=""/></span>
            {scheduleExists && isOpen247 ? (
              <li className="show">
                <span className="ocTime">
                  <span id="dayName">Open </span>
                  <span className="open">24/7</span>
                </span>
              </li>
            ) : (
              <>
                {currentDaySchedule && scheduleExists && !utils.closedDays(closedDates).includes(currentDaySchedule.day) ? (
                  <>
                    <Time currentSlot={currentTimeSlot}/>
                    <OverlayTrigger trigger="click" rootClose  placement="bottom" overlay={popoverSchedule}>
                      <img className={scheduleExists && "chevron"} id="scheduleChevron" onClick={(e)=>{e.stopPropagation()}} src={IconVector} alt=""/>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <span className="ocTime">
                      {!schedules?.length ? (
                        <span>Not available</span>
                      ) : (
                        <span className="close">Closed</span>
                      )}
                    </span>
                    <OverlayTrigger trigger="hover" rootClose placement="bottom" overlay={popoverSchedule}>
                      <img className={scheduleExists && "chevron"} src={scheduleExists ? IconVector : ""} alt=""/>
                    </OverlayTrigger>
                  </>
                )}
              </>
            )}
    </>
  )
}

export default Schedule;


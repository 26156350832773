import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import { api } from "api";
import { local } from "common";
import { actions, messages } from "common/constants";
import { AddressOptions } from "components";
import { DeleteModal } from "modals";

function AddressSelector({
  formik,
  customerAddresses,
  setCustomerAddresses,
  handleAddAddress,
  shopArea,
  newAddress,
  setNewAddress,
  setIsUpdate,
  selectedAddress,
  setSelectedAddress,
  setShowAddressModal,
}) {
  const { token } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chosenAddress, setChosenAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const areaId = searchParams.get('area');

  useEffect(() => {
    const preSelectedAddress = local.getSelectedLocation();
    if (preSelectedAddress && preSelectedAddress.area == areaId && !preSelectedAddress.addedToDB) {
      setChosenAddress(preSelectedAddress);
    }
    if (chosenAddress && preSelectedAddress?.addedToDB) {
      setChosenAddress(null);
    }
  }, [customerAddresses]);

  const handleAction = (id, title, action) => {
    if (action === actions.UPDATE) {
      setIsUpdate(true);
      setShowAddressModal(true);
    } else if (action === actions.DELETE) {
      setShowDeleteModal(true);
    }
    setSelectedAddress({ id, title });
  };

  const handleAddressDelete = async () => {
    setLoading(true);
    const id = selectedAddress.id;
    await api.deleteCustomerAddress(id);
    const addresses = await api.getCustomerAddresses({area: areaId});
    setCustomerAddresses(addresses);
    setShowDeleteModal(false);
    toast.success(messages.ADDRESS_DELETE_SUCCESS);
    const defaultRadio = document.getElementById(`address-${id}`);
    if (defaultRadio) {
      defaultRadio.checked = false;
    }
    if(formik.values.existing_address == selectedAddress.id) {
      formik.setFieldTouched("existing_address", false);
      formik.setFieldValue("existing_address", null);
    }
    if(selectedAddress.id == newAddress) {
      setNewAddress(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(newAddress || customerAddresses.length === 1) {
      const defaultAddressId = customerAddresses.length === 1 ? customerAddresses[0]?.id : newAddress;
      formik.setFieldTouched("existing_address", true);
      formik.setFieldValue("existing_address", defaultAddressId);
      const defaultRadio = document.getElementById(`address-${defaultAddressId}`);
      if (defaultRadio) {
        defaultRadio.checked = true;
      }
    }
  }, [newAddress, customerAddresses]);

  return (
    <section
      className="AddressWrap"
      style={
        formik.values.transport === "PICKUP" || formik.values.transport === null
          ? {display: "none"}
          : {}
      }
    >
      <div>
        {formik.values.transport === "DELIVERY" &&
          (customerAddresses.length > 0 || chosenAddress) &&  (
            <>
              <h3>Choose Address</h3>
              <div className="text-danger">
                {formik.touched.existing_address &&
                  formik.errors.existing_address}
              </div>
              <AddressOptions 
                customerAddresses={customerAddresses} formik={formik} shopArea={shopArea}
                newAddress={newAddress} handleAction={handleAction} showControls={true} 
                chosenAddress={chosenAddress} setChosenAddress={setChosenAddress}
              />
              <DeleteModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                address={selectedAddress}
                handleAddressDelete={handleAddressDelete}
                loading={loading}
              ></DeleteModal>
            </>
          )}
      </div>
      {formik.values.transport === "DELIVERY" && (
        <a onClick={handleAddAddress} className="btnAddAddress">
          <BsPlusCircle className="icon" /> Add new address
        </a>
      )}
    </section>
  );
}

export default AddressSelector;


import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Breadcrumb, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { errors, errorsCodes, labels, routes, schemas, utils, hooks, local } from "common";
import { AddressModal } from "modals";
import { api } from "api";
import "pages/webOrder/_webOrder.scss";
import {
  AddressSelector,
  DeliveryOptions,
  LoaderBtn,
  OrderItems,
  ShopMedia,
} from "components";

function WebOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {token} = useParams();
  const {state} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderLoading, setOrderLoading] = useState(false);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState({id: null, title: ""});
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderFailed, setOrderFailed] = useState(false);
  const [transcriptionInProgress, setTranscriptionInProgress] = useState(false);
  const areaParam = searchParams.get("area");
  const shopParam = searchParams.get("shop");
  const shop = state?.shop;

  const handleOrderFormSubmit = async (values) => {
    if (!token) return;
    let fd = new FormData();
    if (values.transport === "DELIVERY") {
      if(values.existing_address == "chosenAddress") {
        const locationData = local.getSelectedLocation();
        const addr = await api.createCustomerAddresses(
          token, {
            area: locationData.area,
            address: locationData.title,
            coordinates: locationData.coordinates,
          });
          local.storeSelectedLocation({
            addedToDB: true,
          });
        fd.append("customer_address", addr.id);
      } else {
        const foundAddress = customerAddresses.find(
          (ca) => ca.id == values.existing_address
        );
        fd.append("customer_address", foundAddress.id);
      }
    }
    values.order_audio && fd.append("order_audio", values.order_audio);
    values.order_text && fd.append("order_text", values.order_text.trim());
    fd.append("shop", values.shop);
    fd.append("transport", values.transport);
    setOrderLoading(true);
    try {
      await api.createOrder(token, fd);
      setOrderSuccess(true);
    } catch (error) {
      utils.showErrorToast(error);
    }
    setOrderLoading(false);
    ReactGA.event({
      category: 'Customer',
      action: 'Placed Order',
    });
  };

  if (orderSuccess) {
    hooks.usePageTitle(labels.ORDER_COMPLETE);
  } else {
    hooks.usePageTitle(labels.PLACE_ORDER);
  }

  const handleAddAddress = () => {
    setShowAddressModal(true);
  };

  useEffect(() => {
    if (!token) return;
    const fetchData = async () => {
      try {
        const detail = await api.getCustomerDetail(token);
        if(!detail?.has_ongoing_order){
          setErrorMsg(errors.INVALID_LINK);
          setOrderFailed(true);
        }
      } catch (error) {
        if (error?.response?.status === errorsCodes.FORBIDDEN_CODE) {
          setErrorMsg(errors.INVALID_LINK);
          setOrderFailed(true);
        }
        return;
      }
      try {
        const addresses = await api.getCustomerAddresses({area: areaParam});
        setCustomerAddresses(addresses);
      } catch (error) {
        utils.showErrorToast(error);
      }
    };
    fetchData();
  }, [dispatch, token]);

  const handleValidation = (values) => {
    if (!values.order_text) {
      return {
        order_text: "Please provide order text.",
      };
    }
  };

  const formik = useFormik({
    initialValues: {
      shop: shopParam || 0,
      order_text: "",
      order_audio: null,
      transport: shop?.is_pickup_offered ? "PICKUP" : "DELIVERY",
      existing_address: null,
      isValid: false,
    },
    onSubmit: handleOrderFormSubmit,
    validationSchema: schemas.webOrder,
    validate: handleValidation,
    isSubmitting: false,
    dirty: false,
  });

  const handleProductChange = (e) => {
    const product_name = e.target.value;
    if (product_name && product_name !== labels.SELECT) {
      let text = formik.values.order_text;
      text += text ? `\n${product_name}` : product_name;
      formik.setFieldValue("order_text", text);
    }
  };

  const handleAudioDelete = () => {
    formik.setFieldValue("order_audio", null);
  };

  const handleMenuClick = () => {
    setShowMenu(true);
  };

  useEffect(() => {
    if (orderSuccess) {
      navigate(routes.ORDER_COMPLETE, {
        state: { name: shop?.name, errorMsg: errorMsg, area: areaParam },
      });
    }
    else if(orderFailed){
      navigate(-1);
    }
  }, [orderSuccess, orderFailed]);

  return (
    <div className="orderingView">
        <section className="placeOrderView">
          <div className="container">
          <div className="breadcrumbWrap placeOrder">
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate(`/vendors/${areaParam}`)}>{shop?.area?.area} Vendors</Breadcrumb.Item>
              {shop?.category && <Breadcrumb.Item
                onClick={() => navigate(`/vendor-detail/${shop?.id}`)}
              >
                {shop?.name}
              </Breadcrumb.Item>
              }
              <Breadcrumb.Item active>Order</Breadcrumb.Item>
            </Breadcrumb>
          </div>
            <div className="row">
              <h1>Place your order</h1>
              <div className="contentWrap">
                <div className="sectionsWrap">
                  <Form className="sectionsWrap" onSubmit={formik.handleSubmit}>
                    <section className="contentSection">
                      <div className="head">
                        <h2>Type or dictate your order</h2>
                        <p>
                          Customize your order by adding items, giving specific
                          instructions and voice notes for a personalized
                          experience.
                        </p>
                      </div>
                      <OrderItems
                        products={shop?.products}
                        handleProductChange={handleProductChange}
                        formik={formik}
                        handleAudioDelete={handleAudioDelete}
                        transcriptionInProgress={transcriptionInProgress}
                        setTranscriptionInProgress={setTranscriptionInProgress}
                      />
                    </section>
                    <section className="contentSection">
                      <DeliveryOptions shop={shop} formik={formik}/>
                      <AddressSelector
                        formik={formik}
                        customerAddresses={customerAddresses}
                        setCustomerAddresses={setCustomerAddresses}
                        handleAddAddress={handleAddAddress}
                        shopArea={shop?.area}
                        newAddress={newAddress}
                        setNewAddress={setNewAddress}
                        setIsUpdate={setIsUpdate}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        setShowAddressModal={setShowAddressModal}
                      />
                    </section>
                    <div className="btn-container">
                      <LoaderBtn
                        id="submit-btn"
                        title={labels.PLACE_ORDER}
                        loading={orderLoading}
                        disabled={!formik.isValid || transcriptionInProgress}
                      />
                    </div>
                    {(showAddressModal || isUpdate ) && <AddressModal
                      centroid={shop?.centroid}
                      shopArea={shop?.area}
                      show={showAddressModal}
                      setShow={setShowAddressModal}
                      setCustomerAddresses={setCustomerAddresses}
                      setNewAddress={setNewAddress}
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                      isUpdate={isUpdate}
                      setIsUpdate={setIsUpdate}
                    />}
                  </Form>
                </div>
                <ShopMedia
                  shop={shop}
                  handleMenuClick={handleMenuClick}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  showMenu={showMenu}
                  setShowMenu={setShowMenu}
                />
              </div>
            </div>
          </div>
        </section>
    </div>
  );
}

export default WebOrder;


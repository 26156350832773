import React, { useEffect, useRef, useState } from 'react';
import { CustomPagination, Review } from 'components';
import { WriteReviewImage } from "assets/images";
import { ReviewModal, SuccessModal } from 'modals/index';
import 'pages/vendorDetail/_vendorDetail.scss'
import 'scss/_buttons.scss';
import { useDispatch, useSelector } from "react-redux";
import { useLoginContext } from "contexts";
import { api } from "api";
import { globals, local, utils } from "common";
import { labels, messages } from 'common/constants';
import { generalExtraActions } from "reduxStore/actions";
import { authActions } from "../../reduxStore/reducers/authSlice";
import { toast } from 'react-toastify';
import { BsStarFill } from 'react-icons/bs';

function ReviewSection({shopId, shopName, avgRating, setAvgRating, reviews, pageNumber, setPageNumber, loading, className}) {
  const dispatch = useDispatch();
  const reviewRef = useRef();
  const {isUserLoggedIn} = useSelector((state) => state.auth);
  const {showLoginModal} = useLoginContext();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [customerReview, setCustomerReview] = useState(null);
  const [reviewLabel, setReviewLabel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingReview, setPendingReview] = useState(false);
  const totalPages = reviews ? Math.ceil(reviews.count / globals.REVIEWS_PAGE_SIZE) : 0;
  const [reviewsCount, setReviewsCount] = useState(0);

  const handleReviewClick = () => {
    if (local.isUserLoggedIn()) {
      setShowReviewModal(true);
    } else {
      dispatch(authActions.logout());
      showLoginModal(true);
      setPendingReview(true);
    }
  };

  const handleReviewFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = await api.createCustomerReview(shopId, values);
      setCustomerReview(data);
      setAvgRating(utils.updateRating(avgRating, reviewsCount, values.rating));
      if (pageNumber === 1) {
        dispatch(generalExtraActions.getCustomerReviews({shopId}));
      } else {
        setPageNumber(1);
      }
      setShowReviewModal(false);
      setShowSuccessModal(true);
      setReviewLabel(labels.EDIT_REVIEW);
    } catch (error) {
      utils.showErrorToast(error);
    }
  };

  const handleEditReview = async (values) => {
    try {
      setIsLoading(true);
      const oldRating = customerReview?.rating;
      const data = await api.updateCustomerReview(shopId, values);
      setCustomerReview(data);
      setAvgRating(
        utils.updateRating(
          avgRating,
          reviewsCount,
          values.rating,
          oldRating,
        )
      );
      setShowReviewModal(false);
      toast.success(messages.UPDATE_SUCCES);
    } catch (error) {
      utils.showErrorToast(error);
    }
  }

  useEffect(()=>{
    const fetchData = async () => {
      try{
        if(isUserLoggedIn) {
          setCustomerReview(await api.getCustomerReviewDetail({shopId}));
          if(pendingReview) {
            setPendingReview(false);
            setShowReviewModal(true);
          }
        }
      } catch (error) {
        utils.showErrorToast(error);
      }
    }
    fetchData();
  }, [isUserLoggedIn])

  useEffect(()=>{
    if(isUserLoggedIn && (customerReview?.review || customerReview?.rating)) {
      setReviewLabel(labels.EDIT_REVIEW);
    } else {
      setReviewLabel(labels.WRITE_REVIEW);
    }
  }, [isUserLoggedIn, customerReview])

  useEffect(()=>{
    reviews?.count && setReviewsCount(reviews.count);
  },[reviews])

  useEffect(()=>{
    if(isLoading) {
      setTimeout(() => {
        setIsLoading(false);  
      }, 500);
    }
  },[customerReview])

  useEffect(() => {
    reviewRef?.current.scrollIntoView({behavior: "smooth"})
  }, [pageNumber]);

  return (
    <div ref={reviewRef} className={className}>
      <div className="container">
        <div className="row">
          <h2>
            Ratings & Reviews
            {avgRating && reviewsCount && 
              <>
              <span>-</span>
                <BsStarFill color='#6C5CE7CC' size={"15px"}></BsStarFill>
                <span>{avgRating}</span>
                
                <span className='reviewCount'>({reviewsCount} {utils.getReviewLabel(reviewsCount)})</span>
              </>
            }
          </h2>
        </div>
        <div className="row wrap">
          <div className={reviewsCount > 0 && "col-lg-8"}>
            <ul className="reviewsList">
              {reviews?.results?.map((review, index) => {
                return <Review key={index} review={review} shopId={shopId} customerReview={customerReview}></Review>
              })}
            </ul>
            {reviewsCount > 5 &&
              <CustomPagination totalPages={totalPages} pageNumber={pageNumber} setPageNumber={setPageNumber}/>}
          </div>
          <div className="col reviewHolder">
            <div className="writeReview">
              <div>
                <h5>Share Your Experience</h5>
                <h4>Help Others by Sharing Your Thoughts About {shopName}</h4>
              </div>
              <img src={WriteReviewImage} alt=""/>
              <button onClick={handleReviewClick} id="addEditReview" className="btn btn-primary" data-bs-target="#reviewModal"
                      data-bs-toggle="modal">
                {reviewLabel}
              </button>
            </div>
          </div>
          <ReviewModal shopName={shopName} show={showReviewModal} setShowReviewModal={setShowReviewModal}
                       setShowSuccessModal={setShowSuccessModal} loading={isLoading} handleEditReview={handleEditReview}
                       onSubmit={handleReviewFormSubmit} customerReview={customerReview}/>
          <SuccessModal show={showSuccessModal} setShow={setShowSuccessModal}/>
        </div>
      </div>
    </div>
  )
}

export default ReviewSection;
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "react-bootstrap";
import { Envelope, IconLocation } from "assets/icons";
import { LoaderBtn } from "components";
import { errors, labels, schemas, utils } from "common";
import { api } from "api";
import { RECAPTCHA_SITE_KEY } from "config";
import { messages } from "common/constants";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const reCaptchaRef = useRef();
  
  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();

      if (!token) {
        utils.showErrorToast(errors.CAPTCHA_FAILED);
        setLoading(false);
        return;
      }

      const data = {
        email: values.email,
        name: values.name,
        phone_number: values.phone,
        body: values.body,
        captcha_token: token,
      };

      await api.createContactRequest(data);
      formik.resetForm();
      toast.success(messages.MESSAGE_SENT_SUCCESS);
    } catch (error) {
      utils.showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phone: "",
      body: "",
    },
    validationSchema: schemas.contactUs,
    onSubmit: (values) => handleFormSubmit(values),
  });

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h2>Contact Us</h2>
        <p>
          For questions, technical support, or collaboration opportunities,
          <br></br>
          reach out using the contact information provided.
        </p>
      </div>
      <div className="contact-content">
        <div className="contact-info">
          <div>
            <img src={Envelope} />
            <span>support@fatafat.io</span>
          </div>
          <div>
            <img className="locationImg" src={IconLocation} />
            <span>25 Canal Rd, Westwood Colony, Lahore, Punjab 54000</span>
          </div>
        </div>
        <Form onSubmit={formik.handleSubmit} className="contact-form">
          <div className="field">
            <Form.Control
              type="email"
              name="email"
              placeholder="Your Email*"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.email && !!formik.errors.email}
            />
            {formik.touched.email && formik.errors.email && (
              <span className="text-danger">{formik.errors.email}</span>
            )}
          </div>
          <div className="form-row">
            <div className="phone field">
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger">{formik.errors.name}</div>
              )}
            </div>
            
            <div className="phone field">
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.phone && !!formik.errors.phone}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="text-danger">{formik.errors.phone}</div>
              )}
            </div>
            
          </div>
          <div className="field">
            <Form.Control
              as="textarea"
              name="body"
              placeholder="Message*"
              value={formik.values.body}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.body && !!formik.errors.body}
            />
            {formik.touched.body && formik.errors.body && (
              <div className="text-danger">{formik.errors.body}</div>
            )}
            <span className="disclaimer">
              This site is protected by reCAPTCHA and the Google
              <a target="_blank" href="https://policies.google.com/privacy">
                {" "}
                Privacy Policy
              </a>{" "}
              and
              <a target="_blank" href="https://policies.google.com/terms">
                {" "}
                Terms of Service
              </a>{" "}
              apply.
            </span>
          </div>
          <div className="captcha field">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              ref={reCaptchaRef}
              size="invisible"
            />
          </div>
          <LoaderBtn title={labels.SUBMIT} loading={loading} disabled={!formik.isValid || loading}/>
        </Form>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { AddressOptions, LoaderBtn, Loading } from "components";
import { api } from "api";
import { IconBackSmall } from "assets/icons";
import { labels, local } from "common";
import { generalExtraActions } from "reduxStore/actions";
import "modals/addressModals/_addressModals.scss";
import "pages/webOrder/_webOrder.scss";

const ChooseAddress = ({ shopArea, setShowChooseAddressModal }) => {
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const { id: areaId } = useParams();
  const dispatch = useDispatch();

  const handleAddressSubmit = () => {
    const address = customerAddresses?.find(
      (ca) => ca.id == formik.values.existing_address
    );
    dispatch(
      generalExtraActions.getShopsFromStart({
        areaId,
        params: {
          longitude: address.coordinates.longitude,
          latitude: address.coordinates.latitude,
        },
      })
    );
    const addressString = `${address?.title}, ${shopArea?.area}`;
    local.storeSelectedLocation({
      title: addressString,
      area: parseInt(areaId),
      coordinates: address.coordinates,
      isBrowserDetected: false,
      addedToDB: true,
    });
  };

  const formik = useFormik({
    initialValues: {
      existing_address: null,
    },
    onSubmit: handleAddressSubmit,
    isSubmitting: false,
    dirty: false,
  });

  const handleAddresses = async () => {
    const res = await api.getCustomerAddresses({
      area: areaId,
      coordinates: true,
    });
    setCustomerAddresses(res);
  };

  const handleBack = () => {
    setShowChooseAddressModal(false);
  };

  useEffect(() => {
    handleAddresses();
  }, []);

  useEffect(() => {
    const preSelectedAddress = local.getSelectedLocation();
    const selectedAddress = preSelectedAddress && customerAddresses.find(a => a?.title == preSelectedAddress?.title);
    if (customerAddresses.length === 1 || selectedAddress) {
      const defaultAddressId = selectedAddress ? selectedAddress.id : customerAddresses[0]?.id;
      formik.setFieldTouched("existing_address", true);
      formik.setFieldValue("existing_address", defaultAddressId);
      const defaultRadio = document.getElementById(
        `address-${defaultAddressId}`
      );
      if (defaultRadio) {
        defaultRadio.checked = true;
      }
    }
  }, [customerAddresses]);

  return (
    <div>
      <div>
        <Modal.Header closeButton>
          <a className="mBackTop" onClick={handleBack}>
            <img src={IconBackSmall} alt="" />
          </a>
          <Modal.Title as="h1">Choose From Existing Addresses</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <section className="AddressWrap">
              <div>
                {customerAddresses.length > 0 && (
                  <AddressOptions
                    customerAddresses={customerAddresses}
                    filteredAddresses={customerAddresses}
                    shopArea={shopArea}
                    formik={formik}
                    isChooseAddressModal={true}
                  />
                )}
              </div>
            </section>
            {customerAddresses.length == 0 && (
              <Loading
                containerClass={"placeholderContainer"}
                outerClass={"outer"}
                innerClass={"inner"}
                count={4}
              />
            )}
          </Modal.Body>

          <Modal.Footer>
            <LoaderBtn
              title={labels.SUBMIT}
              onClick={handleAddressSubmit}
              disabled={!formik.dirty}
            ></LoaderBtn>
          </Modal.Footer>
        </Form>
      </div>
    </div>
  );
};

export default ChooseAddress;

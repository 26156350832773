import React from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { SuccessImg } from "assets/images";
import { WhatsAppIconGreen } from "assets/icons";
import { routes, hooks, labels } from "common";
import "pages/webOrder/_webOrder.scss";

function OrderSuccessFailure() {
  const {state} = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(state?.area ? `/vendors/${state?.area}` : routes.HOME);
  };

  hooks.usePageTitle(state?.errorMsg ? labels.INVALID_PATH : labels.ORDER_COMPLETE)

  return (
    <div className="orderingView">
      <div className="successWrap">
        <div className="content">
          <img src={SuccessImg}></img>
          {!state?.errorMsg ? (
            <>
              <span className="header">Order Placed Successfully</span>
              <span className="subheading">Thank you for choosing {state?.name}!</span>        
              <div className="text">
                <span>
                  Keep an eye on{" "}
                  <span className="whatsapp">WhatsApp</span>{" "}
                  <img 
                    src={WhatsAppIconGreen}
                    className="icon"
                  />
                  We’ll ping you soon with the total and delivery details.
                </span>
              </div>
            </>
          ) : (
            <>
            <span className="header">Oops! Invalid Path</span>    
              <div className="text">
              <span>{state?.errorMsg}</span>
            </div>
            </>
          )}

          <Button
            style={{ width: "244px", height: "60px", fontWeight: "600" }}
            variant="primary"
            onClick={handleGoBack}
          >
            Explore Your Area
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccessFailure;


import '_app.scss';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from 'components';
import { Home, OrderAddress, Privacy, VendorDetail, Vendors, WebOrder, OrderSuccessFailure } from "pages";
import { globals, routes } from "common";
import { ToastContainer } from "react-toastify";
import { GA_MEASUREMENT_ID, GTM_ID } from 'config';
import 'react-toastify/dist/ReactToastify.css';
import TagManager from 'react-gtm-module';

TagManager.initialize({ gtmId: GTM_ID })
ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
  
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname 
    });
  }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index path={routes.HOME} element={<Home/>}></Route>
          <Route index path={routes.WEB_ORDER} element={<WebOrder/>}></Route>
          <Route index path={routes.ORDER_COMPLETE} element={<OrderSuccessFailure/>}></Route>
          <Route index path={routes.VENDORS} element={<Vendors/>}></Route>
          <Route index path={routes.VENDOR_DETAIL} element={<VendorDetail/>}></Route>
          <Route index path={routes.PRIVACY} element={<Privacy/>}></Route>
        </Route>
        <Route index path={routes.ORDER} element={<OrderAddress/>}></Route>
      </Routes>
      <ToastContainer autoClose={globals.TOAST_TIMER}/>
    </BrowserRouter>
  );
}

export default App;


import { API_BASE_URL } from 'config';

const V1 = `${API_BASE_URL}/api/v1`;
const V2 = `${API_BASE_URL}/api/v2`;
const AUTH_V1 = `${V1}/authentication`;
const USERS_V1 = `${V1}/users`;
const LOCATION_V1 = `${V1}/location`;
const SHOPS_V1 = `${V1}/shops`;
const CUSTOMERS_V1 = `${V1}/customers`;
const ORDERS_V2 = `${V2}/orders`;

export const endpoints = {
  LOGIN: `${USERS_V1}/login/`,
  AREAS: `${LOCATION_V1}/areas/`,
  CATEGORIES: `${SHOPS_V1}/shop_categories/`,
  SHOPS: (id) => `${LOCATION_V1}/areas/${id}/shops/`,
  ALL_SHOPS: (id) => `${LOCATION_V1}/areas/${id}/shops/all/`,
  SHOP_SUGGESTIONS: (id) => `${LOCATION_V1}/areas/${id}/shops/suggestions/`,
  SEND_OTP: `${AUTH_V1}/send-otp/`,
  CHECK_OTP: `${AUTH_V1}/check-otp/`,
  VERIFY_OTP: `${AUTH_V1}/verify-otp/`,
  REFRESH_TOKEN: `${AUTH_V1}/token/refresh/`,
  SHOP_DETAIL: (id) => `${SHOPS_V1}/${id}/shop_detail/`,
  CUSTOMER_ORDER_CREATE: `${ORDERS_V2}/customer_order_create/`,
  CUSTOMER_ADDRESSES: (id) => id ? `${CUSTOMERS_V1}/customer_addresses/${id}/` : `${CUSTOMERS_V1}/customer_addresses/`,
  CUSTOMER_DETAIL: `${CUSTOMERS_V1}/detail/`,
  CREATE_ORDER: `${ORDERS_V2}/order_create/`,
  CUSTOMER_REVIEWS: (id) => `${SHOPS_V1}/${id}/reviews/`,
  REVIEW_DETAIL: (id) => `${SHOPS_V1}/${id}/customer_review/`,
  ADDRESS: `${LOCATION_V1}/geocode/`,
  SECTORS: `${LOCATION_V1}/sectors/`,
  BLOCKS: `${LOCATION_V1}/blocks/`,
  TRANSCRIPTION: `${ORDERS_V2}/transcription/`,
  RAW_TRANSCRIPTION: (id) => `${ORDERS_V2}/raw-transcription-poll/${id}/`,
  PROCESSED_TRANSCRIPTION: (id) => `${ORDERS_V2}/processed-transcription-poll/${id}/`,
  CONTACT_REQUEST: `${CUSTOMERS_V1}/contact/`,
};

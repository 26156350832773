import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { api } from "api";
import { globals, utils } from "common";
import "react-bootstrap-typeahead/css/Typeahead.css";

function SearchBar({ areaId, clear, searchParam, setSearch }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [keyword, setKeyword] = useState("");
  const [options, setOptions] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const typeaheadRef = useRef(null);

  const ActiveIndexWatcher = ({ update }) => {
    useEffect(update);
    return null;
  };

  const fetchOptions = async () => {
    try {
      const res = await api.getShopSuggestions({
        areaId,
        params: { keyword: keyword },
      });
      setOptions(res);
    } catch (error) {
      utils.showErrorToast(error);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === globals.ENTER_KEY && activeIndex === -1) {
      setSearch(keyword);
      typeaheadRef.current?.hideMenu();
      typeaheadRef.current?.blur();
    }
  };

  useEffect(() => {
    if (searchParam !== null) {
      setSearch(searchParam);
      setSingleSelections([{ name: searchParam }]);
    }
  }, [searchParam]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (keyword.trim()) {
        fetchOptions();
      }
    }, 500);
    return () => clearTimeout(delayDebounce);
  }, [keyword]);

  const handleSearchChange = (selected) => {
    setSingleSelections(selected);
    if (selected[0]?.name) {
      setSearch(selected[0]?.name);
      typeaheadRef.current?.blur();
    }
    setOptions([]);
  };

  const handleClear = () => {
    setSingleSelections([]);
    setSearch("");
    setKeyword("");
    setOptions([]);
    typeaheadRef.current.clear();
  };

  return (
    <div className="flex-grow-1 searchfield">
      <Typeahead
        id="conditional-menu"
        labelKey="name"
        onChange={handleSearchChange}
        options={options}
        onInputChange={(text) => {
          setKeyword(text);
        }}
        placeholder="Search..."
        selected={singleSelections}
        onKeyDown={onKeyDown}
        ref={typeaheadRef}
        minLength={2}
      >
        {({ activeIndex }) => (
          <ActiveIndexWatcher update={() => setActiveIndex(activeIndex)} />
        )}
      </Typeahead>
      {clear && (
        <icon onClick={handleClear} class="clear-icon">
          &#x2715;
        </icon>
      )}
    </div>
  );
}

export default SearchBar;

import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { BsPencil, BsTrash } from "react-icons/bs";
import "./_addressOptions.scss";
import { local } from "common";
import { actions } from "common/constants";

const AddressOptions = ({
  customerAddresses,
  shopArea,
  formik,
  newAddress,
  handleAction,
  showControls,
  chosenAddress,
  setChosenAddress,
  isChooseAddressModal,
}) => {
  const ChosenAddressExistsInAddresses = local.getSelectedLocation() && customerAddresses.find(
    (a) => a.title == local.getSelectedLocation().title
  );

  useEffect(() => {
    if (chosenAddress && ChosenAddressExistsInAddresses) {
      setChosenAddress(null);
    }
    if (chosenAddress || isChooseAddressModal) {
      formik.setFieldTouched("existing_address", true);
      if (chosenAddress) {
        formik.setFieldValue("existing_address", "chosenAddress");
      }
      const preSelectedAddr = customerAddresses.find(
        (a) =>
          `${a?.title}, ${shopArea?.area}` === local.getSelectedLocation()?.title
      );
      if (isChooseAddressModal && preSelectedAddr) {
        formik.setFieldValue(
          "existing_address",
          ChosenAddressExistsInAddresses?.id
        );
      }
    }
  }, []);

  return (
    <>
      <ul className="addressList">
        {customerAddresses?.map((ca, index) => {
          return (
            <li className="radio-button-container">
              <Form.Check
                key={index + 1}
                id={`address-${ca.id}`}
                type="radio"
                value={ca.id}
                name="existing_address"
                label={`${ca?.title}, ${shopArea?.area}`}
                isInvalid={
                  formik &&
                  formik.touched.existing_address &&
                  !!formik.errors.existing_address
                }
                onChange={formik && formik.handleChange}
                defaultChecked={
                  (!chosenAddress && (customerAddresses?.length === 1 || ca.id === newAddress)) ||
                  (isChooseAddressModal && `${ca?.title}, ${shopArea?.area}` === `${local.getSelectedLocation()?.title}`)}
              />
              {showControls && (
                <div className="addressControls">
                  <BsPencil
                    className="icon"
                    onClick={() => {
                      handleAction(ca?.id, ca?.title, actions.UPDATE);
                    }}
                  ></BsPencil>
                  <BsTrash
                    className="icon"
                    onClick={() => {
                      handleAction(ca?.id, ca?.title, actions.DELETE);
                    }}
                  ></BsTrash>
                </div>
              )}
            </li>
          );
        })}
        {chosenAddress && (
          <li className="radio-button-container">
            <Form.Check
              id="chosenAddress"
              type="radio"
              value="chosenAddress"
              name="existing_address"
              label={`${chosenAddress.title}`}
              defaultChecked={local.getSelectedLocation()}
              onChange={(e) => formik.handleChange(e)}
            />
            {showControls && (
              <div className="addressControls">
                <BsPencil
                  className="icon"
                  onClick={() => {
                    handleAction(
                      chosenAddress?.id,
                      chosenAddress?.title,
                      actions.UPDATE,
                    );
                  }}
                ></BsPencil>
              </div>
            )}
          </li>
        )}
      </ul>
    </>
  );
};

export default AddressOptions;
